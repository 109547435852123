@import "../../styles/colors.scss";

/* HOME HEADER SECTION */

.about-header {
  flex-direction: column;
  width: 100%;
}

/* HOME CATALOG SECTION */

.about {
  width: 100%;
}

.about-wrap {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 60px 0;
}

.about-info {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 20px;
  text-align: center;

  img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
  }

  h2 {
    margin-bottom: 24px;
    font-size: 32px;
  }

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
    font-weight: 500;
    text-align: justify;
  }

  ul {
    margin-bottom: 20px;
    margin-top: 0;

    li {
      font-weight: 500;
      text-align: left;
    }
  }
}

.about-info .about-item {
  width: 100%;
  margin: 40px 0 0;
}

.about-info .about-item p {
  margin-bottom: 0;
  color: $ui-text-black;
}

.about-info .about-item .about-item-icon {
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin-right: 20px;
  border: 1px solid $ui-line-pink;
  border-radius: 50%;
}

.about-info .about-icon {
  width: 26px;
  height: 26px;
  color: $ui-text-pink;
}

.about-image {
  flex-grow: 0;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.about-item {
  align-items: center;
}

/* HOME FOOTER SECTION */

.about-footer {
  width: 100%;
}

@media all and (max-width: 844px) {
  .about-wrap {
    flex-direction: column;
  }

  .about-wrap .about-image {
    justify-content: center;
    padding: 24px 0 0;
  }

  .about-wrap .about-image img {
    max-width: 100%;
  }
}

@media all and (max-width: 480px) {
  .about-wrap .about-info .about-item {
    flex-direction: column;
    margin: 16px 0 0;
  }

  .about-wrap .about-info .about-item .about-item-icon {
    margin-bottom: 8px;
    margin-right: 0;
  }
}