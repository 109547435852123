@import "../../styles/colors.scss";
.modal-inner.login {
  align-items: center;
  justify-content: center;
  max-width: 480px;
  min-width: 0;
}

.modal-login {
  width: 100%;
  max-width: 480px;

  .modal-body {
    align-items: center;
    justify-content: center;
  }

  .react-tel-input {
    margin-bottom: 8px;
    border: 1px solid #eaeaea;

    .form-control {
      width: 100%;
      border: 0px;
    }
  }

}

.modal-small .modal-body {
  min-width: 0;
  padding: 20px 40px;
}

.modal-body .login-form {
  flex-direction: column;
  width: 320px;
  padding: 8px 0;
}

.modal-body .login-form h3 {
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.modal-body .login-form p {
  margin-bottom: 16px;
  padding: 0 16px;
  color: $ui-text-gray;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
}

.modal-body .login-form .login-send-pass {
  align-items: center;
  justify-content: center;
  padding-top: 8px;
}

.modal-body .login-form .login-form-pass {
  flex-direction: column;
}

.modal-body .login-form .login-form-pass p {
  margin-bottom: 16px;
  margin-top: 8px;
  text-align: center;
}

.modal-body .login-form .login-form-pass-btn .btn-pink-wrap, 
.modal-body .login-form .login-form-pass-btn .btn-pink {
  width: 100%;
}

.modal-body .login-form .login-form-agree-text {
  font-size: 10px;
  line-height: 12px;
}

.modal-body .login-form .login-form-agree-text a {
  color: $ui-text-gray;
  font-weight: 500;
}

@media all and (max-width: 1080px) {
  .modal.modal-small {
    padding: 16px;
  }

  .modal-small .modal-body {
    width: 100%;
  }
}

@media all and (max-width: 991px) {
  .modal.modal-small {
    align-items: center;
    justify-content: center;
  }

  .modal-small .modal-inner {
    width: 360px;
    align-items: center;
    justify-content: center;
  }

  .modal-small .modal-inner .modal-body {
    align-items: center;
    justify-content: center;
  }
}

@media all and (max-width: 420px) {
  .modal-small .modal-inner {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .modal-small .modal-body {
    padding: 20px;
  }

  .modal-small .modal-body .login-form {
    width: 100%;
  }
}