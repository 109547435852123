@import "../../styles/colors.scss";

/* HOME HEADER SECTION */

.classes-header {
  flex-direction: column;
  width: 100%;
}

/* HOME CATALOG SECTION */

.classes-catalog {
  width: 100%;
}

.classes-catalog-wrap {
  flex-direction: column;
  width: 100%;
  padding: 40px 0;
}

.classes-catalog-wrap p:nth-child(3) {
  margin-bottom: 16px;
}

.classes-title {
  width: 100%;
}

.classes-cards {
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 0 -10px;
}

/* HOME FOOTER SECTION */

.classes-footer {
  width: 100%;
}