@import "../../styles/colors.scss";

.place-select-wrap {
    align-items: center;

    .place-select-icon {
        display: none;
    }

    .place-select {
        align-items: center;

        & > span {
            color: $ui-text-dark;
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
        }

        .place-select-button {
            display: flex;
            align-items: center;
            position: relative;

            .place-select-button-icon {
                width: 14px;
                height: 14px;
                margin-top: 2px;
                margin-left: 2px;
                fill: $ui-bg-pink;
            }

            .place-select-button-text1 {
                display: inline;
                color: $ui-text-pink;
                font-weight: 700;
                text-decoration: underline;
            }

            .place-select-button-text2 {
                display: none;
            }

            .place-select-list {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 100;
                flex-direction: column;
                height: 0;
                margin: 0;
                padding: 0;
                border-radius: 12px;
                box-shadow: 0px 2px 8px rgba(24, 24, 24, 0.2);
                background-color: $ui-bg-white;
                opacity: 0;
                overflow: hidden;
                transform: translateY(-8px);
                transition: opacity 0.25s ease-out 0s, transform 0.15s ease-out 0s;

                .place-select-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 3px 0;

                    .place-select-item-icon {
                        margin-right: 10px;

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    span {
                        color: $ui-text-black;
                        font-size: 12px;
                        font-weight: 600;
                        white-space: nowrap;
                    }

                    &.active {
                        span {
                            color: $ui-text-gray;
                        }
                    }
                }

                &.active {
                    height: auto;
                    padding: 12px;
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity 0.25s ease-out 0s, transform 0.15s ease-out 0s;
                }
            }
        }
    }
}

@media all and (max-width: 991px) {
    .place-select-wrap {
        padding: 0 14px;

        .place-select-icon {
            display: flex;
            width: 22px;
            height: 22px;
            margin-right: 8px;
            fill: #db3762;
        }

        .place-select {
            & > span {
                display: none;
            }

            .place-select-button {
                .place-select-button-icon {
                    width: 18px;
                    height: 18px;
                    margin-top: 2px;
                    margin-left: 2px;
                    fill: #db3762;
                }

                .place-select-button-text1 {
                    display: none;
                }

                .place-select-button-text2 {
                    display: inline;
                    color: #db3762;
                    font-weight: 700;
                }

                .place-select-list {
                    //border-radius: 20px;

                    &.active {
                        padding: 12px 20px;
                    }

                    .place-select-item {
                        padding: 6px 0;

                        .place-select-item-icon {
                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }

                        span {
                            color: $ui-text-black;
                            font-size: 14px;
                            font-weight: 600;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}
