@import "../../styles/colors.scss";

.basket-card {
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid $ui-line-light;

  &.no-line {
    padding-bottom: 6px;
    border-bottom-width: 0;
  }
}

.basket-card .basket-card-image {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-right: 16px;
  overflow: hidden;
}

.basket-card .basket-card-image-wrap {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $ui-bg-light;
}

.basket-card .basket-card-image-wrap img {
  width: 100%;
}

.basket-card .basket-card-info {
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin-bottom: 0;
    color: $ui-text-gray;
    font-size: 11px;
    font-weight: 500;
  }
}

.basket-card .basket-card-info h3 {
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
}

.basket-card .basket-card-price {
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  margin-left: 16px;
}

.basket-card .basket-card-price p {
  white-space: nowrap;
}

.basket-card .basket-delivery-btn {
  align-items: flex-start;
}

.basket-card .basket-free-btn {
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}

.basket-card .basket-card-info-btns {
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  padding-bottom: 8px;
}

.basket-card .basket-card-info-btns .btn {
  padding: 0 12px;
  border-radius: 8px;
  border-color: $ui-line-light;
}

.basket-card .basket-card-info-btns input {
  width: 40px;
  border-width: 0;
  text-align: center;
}

.basket-card .basket-card-delete {
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  padding-bottom: 8px;
}

.basket-card .btn.btn-delete {
  padding: 16px;
  border-width: 0;
}

@media all and (max-width: 640px) {
  .basket-card-price-wrap {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .basket-card .basket-card-price {
    margin-left: 0;
  }
}

@media all and (max-width: 540px) {
  .basket-card {
    position: relative;
    padding-bottom: 54px;

    .basket-card-info {
      p {
        margin-bottom: 0;
        color: $ui-text-gray;
        font-size: 11px;
        font-weight: 500;
      }

      .switch {
        position: absolute;
        left: 0;
        bottom: 8px;
        z-index: 9;
      }
    }
  }
}

@media all and (max-width: 460px) {
  .basket-card {
    flex-wrap: wrap;

    .basket-card-price-wrap {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-top: 16px;

      .basket-card-info-btns {
        padding-left: 0;
      }
    }

    .basket-card-delete {
      display: none;
    }
  }
  
}