.loyalty-page {
   display: flex;
   flex-direction: column;
   width: 100%;
}

.loyalty-header {
  flex-direction: column;
  width: 100%;
}

.loyalty-content {
  width: 100%;
  padding: 20px 0;
  position: relative;
}

@media (min-width: 1083px) {
  .page-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .img-content {
    flex-grow: 0;
    flex-shrink: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 1083px) {
  .page-content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .img-content {
    flex-grow: 0;
    flex-shrink: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.p-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  p {
    line-height: 24px;
    font-size: 13px;
    font-weight: 420;
  }
  > span {
    font-size: 18px;
    font-weight: 500;
  }
  ul {
    line-height: 24px;
    font-size: 13px;
    font-weight: 400;
  }
}

.bonus-card {
   margin: 1em 0;
   padding: 1em 0;
}

.bonus-table {
  border:1px solid #545454;
  border-collapse: collapse;
  margin: 5px;
  th  {
    border:1px solid #545454;
  }
 
  td {
    border:1px solid #545454;
    padding-left: 5px;
  }
}