@import "../../styles/colors.scss";

.btn-pink-wrap {
  height: 33px;

  &.btn-big {
    height: 48px;
    margin: 30px 0 10px 0;

    button.btn-pink {
      border-radius: 30px;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 16px;
    }
  }
}

.btn-pink-wrap button.btn-pink,
.btn-pink-wrap a.btn-pink {
  height: 100%;
  border-color: $ui-line-pink;
  background-color: $ui-bg-pink;
  color: $ui-text-white;
}

.btn-pink-wrap a.btn-pink:hover,
.btn-pink-wrap a.btn-pink:hover {
  background-color: $ui-bg-white;
  color: $ui-text-pink;
}

.btn-pink-wrap .btn-pink span + span {
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid $ui-line-white;
  white-space: nowrap;
}

.btn-pink-wrap button.btn-pink.btn-pink-outline,
.btn-pink-wrap a.btn-pink.btn-pink-outline {
  background-color: $ui-bg-white;
  color: $ui-text-pink;
}

.btn-pink-wrap button.btn-pink.btn-pink-outline:hover,
.btn-pink-wrap a.btn-pink.btn-pink-outline:hover {
  background-color: $ui-bg-pink;
  color: $ui-text-white;
}

.btn-pink-wrap button.btn-pink.disabled{
  border-color: $ui-text-gray;
  background-color: $ui-text-light;
  color: $ui-text-white;
}

.btn-loader-icon {
  width: 160px;
  height: 32px;
}