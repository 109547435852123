@import "../../styles/colors.scss";

.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  flex-direction: column;
}

.form-group label {
  font-weight: 500;
}

.form-group .text-area {
  width: 100%;
}