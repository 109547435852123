@import "../../styles/colors.scss";

.input-button input {
  border-color: $ui-line-input;
  border-radius: 20px;
}

.input-button .btn {
  width: auto;
  padding-left: 16px;
  border: 1px solid $ui-line-pink;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: $ui-bg-white;
  color: $ui-text-pink;
  font-size: 13px;
}