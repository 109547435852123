@import "../../styles/colors.scss";
.modal-rules {
  width: 100%;
}

.modal-rules-wrap {
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.modal-rules-wrap h2 {
  margin-bottom: 24px;
  text-align: center;
}

.modal-rules-wrap h3 {
  margin-bottom: 8px;
  text-align: center;
}

.modal-rules-wrap p {
  margin-bottom: 8px;
  text-align: center;
}

.modal-rules-control {
  margin-top: 16px;
}