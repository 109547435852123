@import "../../styles/colors.scss";

.gift-slider-wrap {
  width: 130px;
  height: 200px;
  padding: 8px 20px 8px 0;
}
.home-gift-slider-wrap {
  width: 240px;
  height: 310px;
}

.add-gift-slider {
  position: relative;
  width: 100%;
  height: 220px;
  padding: 16px 0;
  overflow: hidden;

  &.home-add-gift-slider {
    height: 330px;

    .add-gift-slider-btn {
      top: calc(50% - 50px);
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 15px);
    z-index: 9;
    width: 30px;
    height: 30px;
    border: 1px solid $ui-line-light;
    border-radius: 50%;
    background-color: $ui-bg-white;

    &.left-btn {
      left: 0;
    }

    &.right-btn {
      right: 0;
    }

    &-icon {
      color: $ui-text-gray;
    }

    &:hover {
      border-color: $ui-line-pink;
      background-color: $ui-bg-pink;
      transition: all 0.25s ease-out 0s;

      .add-gift-slider-btn-icon {
        color: $ui-text-white;
        transition: all 0.25s ease-out 0s;
      }
    }
  }

  .add-gift-slider-wrap {
    position: absolute;
    transition: all 0.25s ease-out 0s;
  }
}
