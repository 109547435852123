/* COLOR SCHEME */
$black: #000;
$cyan: #008B81;
$cyanLight: #33A29A;
$dark: #333;
$gray: #555;
$light: #f7f7f7;
//$pink: #db3762;
$pink: #008B81;
$white: #fff;

/* background colors */
$ui-bg-black: $dark;
$ui-bg-cyan: $cyan;
$ui-bg-cyan-light: $cyanLight;
$ui-bg-gray: $gray;
$ui-bg-light: $light;
$ui-bg-pink: $pink;
$ui-bg-white: $white;

/* bg transparent colors */
$ui-bg-modal: rgba($black, 0.65);
$ui-bg-hover: rgba(51, 51, 51, 0.2);
$ui-bg-slide: rgba($white, 0.45);
$ui-bg-switch: rgba($black, 0.15);
$ui-bg-title: rgba($black, 0.35);
$ui-btn-pink-light: rgba($pink, 0.15);
$ui-shadow: rgba($black, 0.35);

/* icon colors */
$ui-icon-white: $white;

/* line colors */
$ui-line-gray: #555;
$ui-line-input: #8c8c8c;
$ui-line-tabs: #ccc;
$ui-line-light: #eaeaea;
$ui-line-pink: $pink;
$ui-line-white: $white;

/* text colors */
$ui-text-black: $dark;
$ui-text-dark: #666;
$ui-text-gray: #8c8c8c;
$ui-text-light: #aaa;
$ui-text-pink: $pink;
$ui-text-white: $white;
