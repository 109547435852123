@import "../../styles/colors.scss";

.footer {
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $ui-bg-light;
  color: $ui-text-gray;
}

.footer-inner {
  flex-wrap: wrap;
  padding: 60px 0 34px 0;
}

.footer-col {
  flex-direction: column;
  width: 16.66667%;
  padding-right: 20px;
}

.footer-col:first-child,
.footer-col:last-child {
  width: 33.33333%;
}

.footer-col:last-child {
  padding-right: 0;
}

.footer-col h3 {
  margin-bottom: 24px;
  color: $ui-text-black;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

.footer-col p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}

.footer-contact-item {
  align-items: center;
  margin-bottom: 16px;
}

.footer-contact-item img {
  width: 14px;
  height: 14px;
  margin-right: 12px;
}

.footer-link-item {
  padding-bottom: 12px;
}

.instagram-wrap {
  width: 100%;
  flex-wrap: wrap;
}

/* demo instagram START => prod delete */

.instagram-wrap .inst-card {
  width: 25%;
  padding-top: 25%;
}

.instagram-wrap .inst-card{
  background-size: contain;
  cursor: pointer;
}
 

/* demo instagram END => prod delete */

.sub-footer {
  width: 100%;
  justify-content: center;
  border-top: 1px solid $ui-line-light;
}

.sub-footer-wrap {
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.sub-footer-apps {
  align-items: center;
}

.sub-footer-apps img {
  width: 120px;
  margin-left: 12px;
}

@media all and (max-width: 768px) {

  .footer-inner {
    padding: 30px 0 18px 0;
  }

  .footer-inner .footer-col {
    width: 50%;
    margin-bottom: 8px;
  }

}

@media all and (max-width: 640px) {

  .footer-inner .footer-col {
    width: 100%;
    padding: 0;
    margin-bottom: 8px;
  }

  .sub-footer-wrap {
    flex-direction: column;
  }

  .sub-footer-apps {
    margin-top: 12px;
  }

}