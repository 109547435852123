@import "../../styles/colors.scss";

.check-list {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.check-item {
  display: flex;
  align-items: center;
  padding: 0;
  text-align: left;
}

.check-btn {
  flex-grow: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  border: 2px solid $ui-line-pink;
  border-radius: 2px;
}

.check-btn.check-btn-radio {
  align-items: center;
  justify-content: center;
  border: 1px solid $ui-line-pink;
  border-radius: 8px;
}

.check-btn.active {
  background-color: $ui-bg-pink;
}

.check-btn.check-btn-radio.active {
  background-color: $ui-bg-white;
}

.check-btn img {
  width: 12px;
  height: 12px;
}

.check-btn-inner {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $ui-bg-pink;
}