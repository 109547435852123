@import "../../styles/colors.scss";

.add-gift-slider-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $ui-bg-white;

  &:hover {
    cursor: pointer;
  }

  .card-image {
    flex-grow: 0;
    flex-shrink: 0;
    width: 106px;
    height: 106px;
    margin-right: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .card-info {
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
  }

  .card-info h3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 164px;
    height: 40px;
    margin-top: 6px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .card-info .card-info-price {
    padding: 6px 12px;
    border-radius: 16px;
    background-color: $ui-bg-light;
    color: $ui-text-pink;
    font-weight: 600;
    transition: all 0.35s ease-out 0s;

    &:hover {
      background-color: $ui-btn-pink-light;
      transition: all 0.35s ease-out 0s;
    }
  }
}