@import "../../styles/colors.scss";

button.btn-light,
a.btn-light {
  border-color: $ui-line-light;
}

button.btn-light:hover,
a.btn-light:hover {
  color: $ui-text-pink;
  border-color: $ui-line-pink;
}

button.btn-light:disabled,
a.btn-light:disabled {
  color: $ui-text-light;
  font-weight: 400;
}

button.btn-light:disabled:hover,
a.btn-light:disabled:hover {
  color: $ui-text-light;
  border-color: $ui-line-light;
}
