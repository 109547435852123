@import "../../styles/colors.scss";

.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  flex-direction: column;
}

.form-group label {
  font-weight: 500;
}

.form-group .text-input {
  width: 100%;

  &.overflow {
    overflow: hidden;
  }

  &.text-input-warn {
    border-color: $ui-line-pink;
  }
}

.form-group .text-input.text-right {
  padding-right: 90px;
}

.form-group button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  justify-content: flex-end;
  width: 80px;
  height: 34px;
  padding: 0 16px 0 0;
  border-width: 0;
  text-align: right;
  color: $ui-text-gray;
  font-size: 10px;
}

.modal-body .login-form p.text-input-warn {
  width: 100%;
  padding: 4px 6px;
  text-align: left;
  color: $ui-text-pink;
}