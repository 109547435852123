@import "../../styles/colors.scss";
.modal-payment {
  width: 100%;
}

.order-promo-code {
  width: 250px;
}

.payment-type-wrap {
  flex-wrap: wrap;
  padding-top: 8px;
}

.payment-type-wrap button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin-right: 16px;
  padding: 12px 16px;
  border: 2px solid $ui-line-white;
  border-radius: 4px;
  background-color: $ui-bg-white;
  font-weight: 500;
}

.payment-type-wrap button.active {
  border-color: $ui-line-pink;
}

.payment-type-wrap button img {
  width: 40px;
  height: 40px;
  margin-bottom: 4px;
}

.modal-order-wrap p b {
  font-weight: 600;
}

.modal-order-wrap p span {
  color: $ui-text-pink;
}

.modal-order-wrap .modal-order-price span {
  color: $ui-text-gray;
  text-decoration: line-through;
}

.recipient-wrap {
  flex-direction: column;
}

.recipient-wrap p {
  margin-bottom: 16px;
}

.order-delivery-wrap {
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $ui-line-light;
}

.order-delivery-select {
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 16px;
  padding-bottom: 16px;
  padding-top: 12px;
  border-bottom: 1px solid $ui-line-light;
}

.order-delivery-select p {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  color: $ui-text-pink;
  font-weight: 600;
}

.order-delivery-select.selected p {
  color: $ui-text-black;
}

.modal-order-recipient {
  flex-direction: column;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid $ui-line-light;
}

.modal-order-recipient p {
  display: flex;
  align-items: center;
}

.modal-order-recipient p span {
  margin-right: 8px;
  color: $ui-text-gray;
}

.modal-order-next-wrap {
  justify-content: space-between;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid $ui-line-light;
}

.order-delivery-checklist {
  width: 100%;
  padding: 8px;

  .check-name {
    white-space: normal;
    text-align: left;
  }
}

.order-delivery-new {
  flex-direction: column;
}

.order-delivery-new-wrap {
  flex-wrap: wrap;
}

.order-delivery-new-wrap .form-group {
  max-width: 190px;
  margin-right: 10px;
}

.order-delivery-new-wrap .form-group:last-child {
  margin-right: 0;
}

.order-delivery-datetime {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 0;
}

.order-delivery-datetime-wrap {
  flex-direction: column;
  flex-shrink: 0;
  width: calc(50% - 30px);
  min-width: 200px;
  margin-right: 20px;
}

.order-delivery-datetime-wrap h4 {
  margin-bottom: 3px;
}

.react-calendar,
.react-calendar div  {
  display: block;
  z-index: 999;
}

.react-date-picker .react-date-picker__wrapper {
  padding: 0 12px;
  border-color: $ui-line-light;
  border-radius: 16px;
  background-color: $ui-bg-switch;
  color: $ui-text-black;
  font-weight: 500;
}

.react-date-picker__calendar {
  bottom: auto !important;
  top: calc(100% + 4px) !important;
}

.react-date-picker__calendar .react-calendar {
  border-color: $ui-line-light;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: $ui-text-pink !important;
}

.react-calendar__month-view {
  background-color: $ui-bg-white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
}

.react-date-picker__button__input__input {
  -moz-user-input: none;
  user-input: none;
}

