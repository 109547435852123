@import "../../styles/colors.scss";

.order-card {
  position: relative;
  flex-direction: column;
  width: calc(50% - 16px);
  margin: 8px;
  padding: 16px;
  border: 1px solid $ui-line-light;
}

.order-card .order-card-title {
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.order-card .order-card-status {
  margin-bottom: 4px;
  padding: 3px 12px;
  background-color: $ui-bg-light;
  color: $ui-text-dark;
  font-size: 11px;
  font-weight: 500;
}

.order-card .order-card-status.active {
  background-color: $ui-bg-pink;
  color: $ui-text-white;
}

.order-card .order-card-title h3 {
  margin-bottom: 4px;
  font-size: 14px;
}

.order-card p {
  margin-top: 16px;
}

.order-card-open {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: $ui-bg-hover;
  opacity: 0;
  transition: all .35s ease-out 0s;
}

.order-card-open .order-open-icon {
  width: 32px;
  height: 32px;
  color: $ui-text-white;
}

.order-card:hover .order-card-open {
  opacity: 1;
  transition: all .35s ease-out 0s;
}