@import "../../styles/colors.scss";

.catalog-wrap {
  flex-direction: column;
  width: 100%;
  padding: 24px 0;
}

/* catalog slider */

.hide {
  display: none;
}

.catalog-slider-title {
  padding: 20px 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.22;
  text-transform: uppercase;
}

.catalog-slider-button {
  position: absolute;
  top: calc(50% - 52px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: $ui-bg-cyan;

  &.prev {
    left: -60px;
    padding-right: 4px;
  }

  &.next {
    right: -60px;
    padding-left: 4px;
  }

  .catalog-slider-button-icon {
    width: 24px;
    height: 24px;
    fill: $ui-icon-white;
  }
}

.catalog-slider {
  position: relative;
  width: 100%;
  overflow: hidden;

  .catalog-slider-scroll {
    position: relative;
    z-index: 1;
    width: auto;
    transition: all 0.35s ease-out 0s;

    .catalog-slider-slide {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

@media all and (max-width: 1540px) {
  .catalog-slider-button {
    &.prev {
      left: 0px;
    }

    &.next {
      right: 0px;
    }
  }
}

@media all and (max-width: 1024px) {
  .catalog-slider {
    overflow-x: auto;
    /* Скрываем scrollbar для IE, Edge и Firefox */
    -ms-overflow-style: none; /* IE и Edge */
    scrollbar-width: none; /* Firefox */

    /* Скрываем scrollbar для Chrome, Safari и Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .catalog-slider-scroll {
      .catalog-slider-slide {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .catalog-slider-button {
    top: 25%;
    opacity: 0.74;
  }
}

/* rc slider fix */

.rc-slider-track {
  background-color: #db3762 !important;
}
.rc-slider-handle {
  border-color: #db3762 !important;
}

.rc-slider-handle:focus {
  border-color: #db3762 !important;
  box-shadow: 0 0 5px #db3762 !important;
}
.rc-slider-handle:active {
  border-color: #db3762 !important;
  box-shadow: 0 0 5px #db3762 !important;
}

/* catalog filter */

.catalog-filter {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  padding: 0 20px;
}

.filter-type {
  flex-wrap: wrap;
  align-items: center;
  overflow-x: auto;
  width: 100%;
}
.filter-type::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.filter-type button {
  padding-right: 20px;
  color: $ui-text-dark;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  white-space: nowrap;
}

.filter-type button.active {
  color: $ui-text-pink;
}

.filter-type a {
  padding-right: 20px;
  color: $ui-text-dark;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  white-space: nowrap;
}

.filter-type a.active {
  color: $ui-text-pink;
}

.filter-select {
  align-items: center;
}

.filter-select-wrap {
  position: relative;
}

.filter-select-btn {
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: $ui-text-dark;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}

.filter-select-btn-icon {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  color: $ui-text-pink;
}

.filter {
  display: none;
  position: absolute;
  top: 100%;
  right: -8px;
  z-index: 100;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  background-color: $ui-bg-white;
  box-shadow: 0 0 5px $ui-shadow;
  opacity: 0;
}

.filter.active {
  display: flex;
  opacity: 1;
}

.filter > button {
  padding: 6px 10px;
  color: $ui-text-dark;
  font-weight: 500;
  transition: all 0.3s ease-out 0s;
}

.filter > button:hover {
  color: $ui-text-pink;
  transition: all 0.3s ease-out 0s;
}

.filter-inner {
  width: 100%;
  align-items: center;
}

.filter-slider-wrap {
  flex-direction: column;
  min-width: 280px;
  padding: 16px 16px;
}

.filter-slider-wrap h3 {
  margin-bottom: 16px;
}

.filter-inner .slider {
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 8px 6px;
}

.filter-inner .slider .value {
  position: relative;
  margin: 8px -6px;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: $ui-bg-pink !important;
}

.filter-inner .filter-slider-wrap button {
  margin: 0;
}

.filter-inner button {
  align-items: center;
  justify-content: center;
  width: 110px;
  margin: 8px;
  padding: 12px;
  border: 1px solid $ui-line-light;
}

.filter-inner button.active {
  background-color: $ui-bg-light;
  color: $ui-text-dark;
  font-weight: 500;
}

.filter-inner button img {
  width: 76px;
  height: 76px;
  margin: 8px 0;
}

.filter-inner input {
  margin: 8px;
  width: 100px;
}

.filter-inner span {
  font-weight: 500;
}

.gifts-line {
  width: 100%;
  flex-wrap: wrap;
  position: relative;

  .catalog-card {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 2;
  }
}

.gifts-line-mobile {
  .catalog-card {
    position: relative;
    bottom: 0;
  }
}

.gifts-line > h3 {
  position: relative;
  width: 100%;
  padding: 0 10px 10px;
  color: $ui-text-dark;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    width: calc(100% - 10px);
    border-bottom: 1px solid $ui-line-light;
  }
}

.mobile-select {
  display: none;
}
.desktop-select {
  display: block;
}

/* catalog */

.catalog {
  flex-direction: column;
  width: 100%;
}

.catalog-category {
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 0 -10px;
}

.catalog-category .category-title {
  width: 100%;
  margin: 24px 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;

  span {
    display: flex;
    align-items: center;
    padding-top: 2px;
    color: $ui-text-dark;
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle;

    .category-title-icon {
      margin-right: 4px;
    }
  }
}

.mobile-catalog-filter {
  display: none;
  padding: 16px 16px 0 16px;
  overflow: hidden;
  overflow-x: auto;
  /* Скрываем scrollbar для IE, Edge и Firefox */
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */

  /* Скрываем scrollbar для Chrome, Safari и Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .mobile-filter-type {
    a {
      display: flex;
      padding-right: 20px;
      color: #666;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-transform: uppercase;
      white-space: nowrap;
      padding: 4px 12px;
      margin-right: 8px;
      margin-bottom: 8px;
      border: 1px solid #eaeaea;
      border-radius: 20px;

      &.active {
        border-color: $ui-bg-cyan;
        background-color: $ui-bg-cyan;
        color: #fff;
        font-weight: 500;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .catalog-wrap {
    padding: 0;
  }

  .catalog-filter {
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
  }

  .filter.active {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .filter.active .filter-inner {
    flex-wrap: wrap;
  }

  .filter-inner button {
    width: calc(50% - 16px);
  }

  .filter-select-btn {
    padding-left: 0;
    padding-right: 20px;
  }

  .filter-type button {
    padding: 4px 12px;
    margin-right: 8px;
    border: 1px solid $ui-line-light;
  }

  .filter-type button.active {
    border-color: $ui-line-pink;
    background-color: $ui-bg-pink;
    color: $ui-text-white;
    font-weight: 500;
  }
}

@media all and (max-width: 991px) {
  .mobile-catalog-filter {
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
  }

  .catalog-slider-button {
    display: none;
  }

  .filter-type {
    display: none;
    flex-wrap: nowrap;
  }

  .mobile-select {
    display: block;
  }
  .desktop-select {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .mobile-catalog-filter {
    padding-left: 20px;
    padding-right: 20px;
  }

  .catalog-category {
    width: calc(100% + 8px);
    margin: 0 -4px;
  }

  .catalog-category .category-title {
    margin: 16px 4px;
  }
}

@media all and (max-width: 480px) {
  .filter-inner button {
    display: flex;
    flex-direction: column;
  }
}

.order-boxes-info {
  padding: 8px 0;

  .btn {
    flex-grow: 0;
    flex-shrink: 0;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px solid $ui-line-tabs !important;
    background-color: $ui-bg-light;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    font-size: 16px;
  }
}
