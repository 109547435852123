@import "../../styles/colors.scss";

.history-card {
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
  padding: 8px;
  
  .switch-wrap {
    width: 100%;
    padding-top: 6px;
  }

  .history-card-content {
    width: 100%;
  }

  .history-card-image {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 12px;
    overflow: hidden;
  }

  .history-card-image img {
    width: 100%;
  }

  .history-card-image img.delivery-image {
    border: 1px solid $ui-line-light;
  }

  .history-card-info {
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    padding-right: 16px;
  }

  .history-card-info h3 {
    margin: 0 0 6px 0;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
  }

  .history-card-price {
    margin: 12px 0 6px 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }

  .history-card-delete {
    .btn-delete {
      border-width: 0;
      padding-top: 6px;
    }
  }
}

@media all and (max-width: 480px) {
  .history-card-info {
    p {
      position: relative;
      width: calc(100% + 80px);
    }
  }

  .history-card .history-card-price {
    margin-top: 0;
  }
  
  .history-card .history-card-delete .btn-delete {
    padding: 0 0 0 32px;
    height: 100%;
  }
}