@import "../../styles/colors.scss";

.delivery-wrap {
  width: 100%;
  padding: 50px 0;
  flex-direction: column;
}

.delivery-info {
  width: 100%;
  flex-direction: column;
}

.delivery-item {
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;

  .delivery-item-title {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 8px;

    h3 {
      flex-grow: 0;
      flex-shrink: 1;
      margin-right: 8px;
      font-size: 16px;
    }

    .delivery-item-title-line {
      flex-grow: 1;
      flex-shrink: 1;
      border-bottom: 1px dotted $ui-line-tabs;
    }

    p {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 8px;
      color: $ui-text-pink;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.delivery-info-text {
  flex-direction: column;
  width: 100%;

  h3 {
    margin-bottom: 24px;
    text-align: center;
  }

  p {
    margin-bottom: 12px;
    text-align: left;
  }
}

@media all and (max-width: 991px) {
  .delivery-wrap {
    padding: 20px 0;
  }

  .delivery-item {
    .delivery-item-title {
      flex-direction: column;

      p {
        margin-left: 0;
        margin-top: 12px;
      }

      .delivery-item-title-line {
        display: none;
      }
    }
  }
}
