@import "../../styles/colors.scss";

.header-wrap {
  position: relative;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* header info */

.head-info {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  background-color: $ui-bg-gray;
}

.head-info .wrapper {
  justify-content: space-between;
}

.head-info-about {
  align-items: center;
  justify-content: flex-start;
}

.about-item {
  align-items: center;
  padding-right: 30px;
}

.about-item img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.about-item p {
  color: $ui-text-white;
}

.about-item p span {
  font-weight: 500;
}

.head-info-contact {
  align-items: center;
}

.contact-item {
  align-items: center;
  padding-left: 16px;
}

.contact-item a {
  padding: 0 6px;
  color: $ui-text-white;
  font-size: 14px;
}

.contact-item a:hover {
  color: $ui-text-pink;
}

.contact-item a .contact-item-img,
.contact-item a img {
  width: 22px;
  height: 22px;
  margin: 2px;
  transition: all 0.15s linear 0.15s;
}

.contact-item a:hover .contact-item-img,
.contact-item a:hover img {
  width: 26px;
  height: 26px;
  margin: 0;
  transition: all 0.15s linear 0.15s;
}

.contact-item a span:first-child {
  display: none;
}

/* header */

.header {
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
}

.header-logo {
  align-items: flex-end;
  padding: 6px 0 24px 0;
}

.header-logo .logo-image {
  width: 115px;
  margin-right: 80px;
}

.header-about {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 2px;

  .rating {
    a {
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-weight: 600;

      span {
        margin-left: 5px;
      }
    }
  }
}

.header-about p {
  color: $ui-text-dark;
  font-size: 14px;
  line-height: 20px;
}

.header-about p a {
  color: $ui-text-pink;
  font-weight: 600;
}

.header-about .delivery-time {
  color: $ui-text-gray;
  font-weight: 600;
}

/* profile */

.header-profile {
  align-items: center;
}

.profile-basket {
  padding: 0 8px;
}

.profile-login {
  padding-left: 8px;
}

/* nav menu */

.nav-menu-wrap {
  width: 100%;
  justify-content: center;
  background-color: $ui-bg-white;
}

.nav-menu-wrap.scroll {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid $ui-line-light;
  box-shadow: 0 -2px 5px $ui-shadow;
}

.nav-menu {
  .nav-menu-container {
    width: 100%;
    height: 60px;
    box-shadow: 0px 3px 9px rgba(24, 24, 24, 0.12);
    border: 0px;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: $ui-bg-white;
  }
}

.nav-menu-wrap.scroll .nav-menu {
  border-width: 0;
  justify-content: space-between;
}

.nav-menu-left {
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: space-between;
}

.nav-menu .logo-image {
  width: 0;
  margin-right: 0;
}

.nav-menu-wrap.scroll .logo-image {
  width: 80px;
  margin-right: 36px;
  transition: all 0.3s ease-out 0s;
}

.nav-menu-basket {
  display: none;
}

.nav-menu-wrap.scroll .nav-menu-basket {
  display: flex;
  align-items: center;
}

.nav-menu-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-menu-item {
  display: inline-block;
  color: $ui-text-black;
  text-transform: uppercase;

  &.dropdown-menu-item {
    position: relative;
    padding: 10px 20px 10px 6px;

    button {
      margin-right: 8px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .dropdown-menu-inner-list {
      position: absolute;
      top: calc(100% + 8px);
      height: 0;
      overflow: hidden;
      padding: 0;
      border-radius: 20px;
      background-color: $ui-bg-white;
      box-shadow: 0px 3px 9px rgba(24, 24, 24, 0.12);
      opacity: 0;
      transform: translateY(-20px);
      transition: opacity 0.35s ease-out 0.15s, transform 0.35s ease-out 0.15s;

      li {
        list-style-type: none;
        display: inline-block;
        padding: 12px 12px;
        line-height: 16px;
        font-weight: 500;

        a {
          padding: 0;
          white-space: nowrap;
        }
      }

      &.active {
        height: auto;
        padding: 20px;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.35s ease-out 0.15s, transform 0.35s ease-out 0.15s;
      }
    }
  }
}

.nav-menu-item a {
  display: inline-block;
  padding: 10px 32px 10px 6px;
  line-height: 16px;
  font-weight: 500;
}

.nav-menu-wrap.scroll {
  .nav-menu-item a {
    font-size: 12px;
    line-height: 14px;
  }

  .nav-menu-container {
    box-shadow: none;
  }
}

.nav-menu-item.active a {
  color: $ui-text-pink;
}

.menu-mobile-btn {
  display: none;
}

.nav-menu-mobile {
  display: none;
}

.logo-image-small {
  display: none;
}

.contact-item-mobile {
  display: none;
}

@media all and (max-width: 1190px) {
  .nav-menu-item a {
    padding: 14px 16px 14px 6px;
  }

  .nav-menu-wrap.scroll .logo-image {
    margin-right: 16px;
  }
}

@media all and (max-width: 1055px) {
  .nav-menu-item a {
    padding: 14px 12px 14px 0;
  }

  .nav-menu-wrap.scroll .logo-image {
    margin-right: 12px;
  }
}

@media all and (max-width: 991px) {
  .header.scroll {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: $ui-bg-white;
  }

  .header.scroll .header-logo {
    padding: 8px 0 4px 0;
  }

  .logo-image {
    display: none;
  }

  .logo-image-small {
    display: inline-block;
    width: 90px;
  }

  .nav-menu-wrap.active-mobile .logo-image-small {
    display: none;
  }

  .head-info-about {
    display: none;
  }

  .head-info-contact {
    width: 100%;
    justify-content: space-between;
  }

  .contact-item {
    padding-left: 0;
  }

  .head-info-contact .contact-item:nth-child(2) {
    display: none;
  }

  .menu-mobile-btn {
    display: flex;
  }

  .header-about {
    position: absolute;
    top: 52px;
    
    .delivery-time,
    .rating {
      display: none;
    }
  }

  .header-profile {
    display: none;
  }

  .header-logo {
    justify-content: space-between;
    width: 100%;
    padding: 8px 0 48px 0;
  }

  .header-logo .logo-image {
    position: relative;
    width: 110px;
    margin-right: 16px;
  }

  .nav-menu-wrap {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    width: 100%;
    height: 100%;
    background-color: $ui-bg-black;
    padding-bottom: 64px !important;
  }

  .nav-menu-wrap.active-mobile {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;

    .nav-menu-container {
      height: auto;
      padding: 0;
      background-color: transparent;

      .dropdown-menu-item {
        padding: 0;

        button {
          color: #fff;
          padding: 14px 12px 14px 0;
        }

        svg {
          display: none;
        }

        .dropdown-menu-inner-list {
          display: flex;
          flex-direction: column;
          position: relative;
          top: 0;
          opacity: 1;
          height: auto;
          background-color: transparent;
          transform: translateY(0);
        }
      }
    }
  }

  .nav-menu-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 0;
  }

  .nav-menu-left {
    flex-direction: column;
    align-items: flex-start;

    .nav-menu-list {
      align-items: flex-start;
    }

    br {
      display: none;
    }
  }

  .nav-menu-mobile-title {
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .nav-menu-mobile-delivery {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 8px;
    color: $ui-text-white;

    .rating-mobile {
      padding-top: 12px;
      a {
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: $ui-text-white;
        font-weight: 600;

        span {
          margin-left: 5px;
        }
      }
    }
  }

  .nav-menu-mobile-delivery p {
    display: flex;
    align-items: center;
  }

  .nav-menu-mobile-delivery p .delivery-icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  .nav-menu-mobile-delivery .btn {
    height: 22px;
    padding: 0;
    border-width: 0;
    color: $ui-text-dark;
  }

  .nav-menu-mobile h2 {
    color: $ui-text-white;
  }

  .nav-menu-mobile .btn {
    color: $ui-text-white;
  }

  .nav-menu {
    padding: 16px 0;
    border-bottom: 1px solid $ui-line-gray;
    border-top: 1px solid $ui-line-gray;
    background-color: transparent;
  }

  .nav-menu-list {
    display: flex;
    flex-direction: column;
  }

  .nav-menu-list .nav-menu-item a {
    color: $ui-text-white;
  }

  .nav-menu-mobile-links {
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 0;
  }

  .nav-menu-mobile-links a {
    padding: 8px 24px 8px 0;
    color: $ui-text-white;
  }

  .nav-menu-mobile-links a p {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .mobile-phone-icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  .nav-menu-mobile-links a img {
    width: 120px;
  }

  .contact-item-mobile {
    display: flex;
  }

  .contact-item-mobile .contact-item {
    padding-right: 16px;
  }

  .contact-item-mobile .contact-item a {
    padding: 0 3px;
  }
}

.nav-menu-list-contact-small {
  display: none;
}

@media all and (max-width: 1460px) {
  .nav-menu-list-contact {
    display: none;
  }
  .nav-menu-list-contact-small {
    display: block;
  }
}

@media all and (max-width: 1024px) { 
  .header.scroll {
    .header-about {
      display: none;
    }
  }
}

@media all and (max-width: 380px) {
  .contact-item a span:first-child {
    display: flex;
  }

  .contact-item a span:last-child {
    display: none;
  }

  .contact-item-call {
    width: 18px;
    height: 18px;
  }
}
