@import "../../styles/colors.scss";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: $ui-bg-modal;
  overflow-y: auto;
}

.modal.show {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
  transition: all 0s ease-out 0s;

  .modal-inner {
    opacity: 1;
  }
}

.modal.hide {
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
  transition: all 0s ease-out 0s;

  .modal-inner {
    opacity: 0;
  }
}

.modal.scroll {
  align-items: flex-start;
}

.modal.center {
  align-items: center;
}

.modal.modal-light {
  background-color: rgba(255, 255, 255, 0.65);
}

.modal-inner {
  position: relative;
  width: 60%;
  max-width: 980px;
  min-width: 620px;
  transition: all 0.45s ease-out 0.15s;
}

.loader {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.loader-gif {
  width: 64px;
  height: 64px;
}

.modal-close-btn {
  position: absolute;
  right: -48px;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: $ui-bg-white;
}

.modal-close-btn-icon {
  width: 24px;
  height: 24px;
}

.modal-close-btn:hover .modal-close-btn-icon {
  width: 26px;
  height: 26px;
}

.modal-body {
  flex-direction: column;
  width: 100%;
  padding: 20px;
  background-color: $ui-bg-light;
  box-shadow: 0 0 15px $ui-shadow;
}

@media all and (max-width: 1080px) {
  .modal {
    padding: 16px;
  }

  .modal-close-btn {
    right: 4px;
    top: 4px;
    z-index: 9;
    background-color: $ui-bg-light;
  }
}

@media all and (max-width: 991px) {
  .modal {
    align-items: flex-start;
  }

  .modal-inner {
    width: 100%;
  }
}

@media all and (max-width: 768px) {
  .modal-inner {
    min-width: 280px;
  }

  .modal-body {
    padding: 8px;
  }
}
