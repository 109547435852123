@import "../../styles/colors.scss";

.contacts-wrap {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 0;

  h3 {
    margin-bottom: 12px;
    font-size: 20px;
    text-align: center;
  }

  .contacts-info-wrap {
    width: 100%;
    margin: 50px 0;
    flex-wrap: wrap;
  }

  .contacts-info-item-link {
    display: flex;
    flex: 1;

    .contacts-info-item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-right: 1px solid $ui-line-pink;
      padding: 12px 24px;

      .contacts-info-item-icon {
        width: 52px;
        height: 52px;
        margin-bottom: 12px;
      }

      h4 {
        margin-bottom: 8px;
        font-size: 16px;
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .contacts-wrap {
    .contacts-info-wrap {
      padding: 20px 0;
    }
  }
}
