@import "../../styles/colors.scss";

.modal-delivery {
  width: 100%;
}

.modal-delivery-wrap {
  flex-direction: column;
}

.modal-delivery-control {
  justify-content: space-between;
}

.delivery-type-wrap {
  flex-direction: column;
  padding-top: 16px;
}

.delivery-type-info {
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0;
}

.delivery-type-info p {
  color: $ui-text-black;
  font-weight: 400;
}

.delivery-type-info p span {
  color: $ui-text-pink;
  font-weight: 600;
}

.delivery-date-time-select {
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;

  .react-date-picker {
    margin-bottom: 8px;
    margin-right: 16px;
  }

  .rc-time-picker-input {
    height: 29px;
    padding: 0 12px;
    border-color: #eaeaea;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.15);
    color: #333;
    font-weight: 500;
  }

  .rc-time-picker-clear-icon:after {
    color: $ui-text-black;
    font-size: 14px;
  }
}