@import "../../styles/colors.scss";

.gift-card {
  cursor: pointer;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  padding: 0 10px;

  &.category-card {
    width: 20%;

    .gift-card-image {
      padding-top: 100%;
    }

    .gift-card-slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    .gift-card-info {
      p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
      }

      h3 {
        margin-top: 0;
        text-transform: lowercase;
      }
    }
  }
}

.gift-card-image {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
}

.gift-card-image img {
  position: relative;
  z-index: 1;
  width: 100%;
  transition: transform 1s;
}

.gift-card-image:hover img {
  transform: scale3d(1.1, 1.1, 1);
  transition: transform 1s;
}

.gift-card-image:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: calc(100% - 4px);
  background-color: $ui-bg-hover;
  opacity: 0;
  transition: opacity 1s;
}

.gift-card-image:hover:before {
  opacity: 1;
  transition: opacity 1s;
}

.gift-card-slider {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.gift-image-slide {
  position: absolute;
  z-index: 0;
  left: 10%;
  flex-direction: column;
  opacity: 0;
}

.gift-image-slide.active {
  position: relative;
  z-index: 1;
  left: 0;
  opacity: 1;
  transition: left 0.65s, opacity 1s;
}

.gift-slider-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 4px;
  background-color: $ui-bg-slide;
  opacity: 0;
  transition: opacity 1s;
}

.gift-card-image:hover .gift-slider-controls {
  opacity: 1;
  transition: opacity 1s;
}

.gift-slider-controls .slide-btn {
  flex: 1;
  height: 4px;
  padding: 0;
  background-color: transparent;
  transition: all 0.3s ease-in-out 0s;
}

.gift-slider-controls .slide-btn.active {
  background-color: $ui-bg-pink;
  transition: all 0.3s ease-in-out 0s;
}

.gift-card-control {
  position: absolute;
  top: 0;
  left: 56px;
  z-index: 4;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
}

.gift-card-image:hover .gift-card-control {
  left: 0;
  opacity: 1;
  transition: all 0.5s ease-in-out 0s;
}

.gift-card-control button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin: 3px 0;
  background-color: $ui-bg-white;
  color: $ui-text-gray;
  transition: all 0.3s ease-in-out 0s;
}

.gift-card-control button:hover {
  background-color: $ui-bg-pink;
  color: $ui-text-white;
  transition: all 0.3s ease-in-out 0s;
}

.gift-card-info {
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.gift-card-info h3 {
  max-height: 40px;
  margin-top: 6px;
  padding-bottom: 0;
  color: $ui-text-black;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gift-card-info p {
  margin-bottom: 16px;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

@media all and (max-width: 768px) {
  .gifts-catalog-categories {
    position: relative;
    width: calc(100% + 20px);
    margin: 0 -10px;
    padding-bottom: 6px;
  }

  .gift-card.resized {
    &.category-card {
      width: auto;
      padding: 0;

      .gift-card-image {
        display: none;
      }

      .gift-card-info {
        padding: 4px 12px;
        margin-right: 8px;
        border: 1px solid #eaeaea;
        border-radius: 4px;
      }
    }
  }

  .gift-card {
    &.category-card {
      width: 50%;
    }
  }
}
