@import "../../styles/colors.scss";

.free-gift-card {
  flex-direction: column;
  width: 25%;
  padding: 0 8px;
}

.free-gift-card-image {
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
  padding-top: 130%;
  overflow: hidden;
}

.free-gift-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .35s, transform .35s;
}

.free-gift-card-image:hover img {
  opacity: 0.7;
  transform: scale3d(1.05, 1.05, 1);
  transition: opacity .35s, transform .35s;
}

.free-gift-card-info {
  flex-direction: column;
}

.free-gift-card-info h3 {
  margin: 12px 0;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.free-gift-card-info p {
  height: 72px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

@media all and (max-width: 768px) {

  .free-gift-card-info p {
    height: 40px;
    line-height: 20px;
  }

}

@media all and (max-width: 640px) {
  
  .free-gift-card {
    width: 50%;
    margin-bottom: 16px;
  }

}