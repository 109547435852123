@import "../../styles/colors.scss";

.modal-inner.catalog {
  width: auto;
}

.modal-catalog {
  width: 100%;
}

/* image */

.modal-catalog-image {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: 500px;
  min-height: 660px;
  margin-right: 24px;

  img {
    width: 100%;
    height: auto;
  }
}

.modal-catalog-slider {
  position: relative;
  z-index: 2;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.modal-catalog-slider-zoom {
  display: block;
  width: 100%;
  overflow: hidden;

  & > div {
    flex-direction: column;
  }

  img {
    width: 500px;
    height: auto;
    display: block;
    user-select: none;
  }

  .sc-bdvvtL {
    width: 100%;
    margin: 0;

    .sc-gsDKAQ {
      display: flex;
      flex: 1;
      height: 6px;
      margin: 0;
      border-radius: 0;
    }
  }
}


.modal-catalog-image-slide {
  display: none;
  position: absolute;
  z-index: 0;
  left: 0;
  flex-direction: column;
  opacity: 1;
}

.modal-catalog-image-slide.active {
  display: flex;
  position: relative;
  z-index: 1;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: left 0.65s, opacity 1s;
}

.modal-catalog-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 4px;
  background-color: $ui-bg-slide;
  opacity: 0;
  transition: opacity 1s;
}

.modal-catalog-image:hover .modal-catalog-controls {
  opacity: 1;
  transition: opacity 1s;
}

.modal-catalog-controls .slide-btn {
  flex: 1;
  height: 4px;
  padding: 0;
  background-color: transparent;
  transition: all 0.3s ease-in-out 0s;
}

.modal-catalog-controls .slide-btn.active {
  background-color: $ui-bg-pink;
  transition: all 0.3s ease-in-out 0s;
}

.modal-catalog-control {
  position: absolute;
  top: calc(50% - 20px);
  left: -33px;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + 66px);
  height: 40px;
  padding: 4px;
  transition: all 0.5s ease-in-out 0s;
}

.modal-catalog-control button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 6px 0;
  color: $ui-text-gray;
  transition: all 0.3s ease-in-out 0s;
}

.modal-catalog-control button:hover {
  color: $ui-text-pink;
  transition: all 0.3s ease-in-out 0s;
}

/* info */

.modal-catalog-info {
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.modal-catalog-info-settings {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.modal-catalog-info-settings .filter-label {
  width: auto;
  margin-bottom: 8px;
  padding: 8px 12px;
  background-color: $ui-bg-pink;
}

.modal-catalog-info-settings .filter-label span {
  color: $ui-text-white;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  text-transform: uppercase;
}

.modal-catalog-info-settings > h3 {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 36px;
}

.modal-catalog-info-settings p {
  margin-bottom: 8px;
  white-space: pre-line;
}

.modal-catalog-info .catalog-setting {
  flex-direction: column;
  width: 100%;
}

.modal-catalog-info .setting-item {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid $ui-line-light;
}

.modal-catalog-info .setting-item .settings-list {
  padding: 8px 0 8px 12px;
  background-color: $ui-bg-white;
}

.modal-catalog-info .setting-item .settings-list button {
  width: 44px;
  height: 44px;
  margin-right: 12px;
  border: 2px solid $ui-line-white;
  background-color: yellow;
  opacity: 0.55;
  transition: all .35s ease-out 0s;
}

.modal-catalog-info .setting-item .settings-list button:first-child {
  background-color: orange;
}

.modal-catalog-info .setting-item .settings-list button:last-child {
  background-color: green;
}

.modal-catalog-info .setting-item .settings-list button.active {
  border: 2px solid $ui-line-light;
  opacity: 1;
  transition: all .35s ease-out 0s;
}

.catalog-setting > p {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
}

.gifts-item {
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
}

.gifts-item > p {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
}

.gifts-item .gifts-list {
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 2px;
  background-color: $ui-bg-white;
}

.gifts-item .gifts-list .gift-list-slide {
  position: absolute;
  top: calc(50% - 15px);
  left: -20px;
  z-index: 0;
  width: 30px;
  height: 30px;
  background-color: $ui-bg-white;
  opacity: 0;
  transition: all .35s ease-out 0s;
}

.gifts-item .gifts-list .gift-list-slide.hide {
  display: none;
}

.gifts-item .gifts-list .gift-list-slide:last-child {
  left: auto;
  right: -20px;
}

.gifts-item .gifts-list:hover .gift-list-slide {
  left: -16px;
  z-index: 10;
  opacity: 1;
  transition: all .35s ease-out 0s;
}

.gifts-item .gifts-list:hover .gift-list-slide:last-child {
  left: auto;
  right: -16px;
  transition: all .35s ease-out 0s;
}

.gifts-item .gifts-list .gift-card {
  margin-bottom: 0;
}

.modal-catalog-info-price {
  flex-direction: column;
}

.modal-catalog-info-settings .switch-item-wrap {
  margin-bottom: 16px;
}

.modal-catalog-info-price p {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 8px;
  color: $ui-text-black;
  font-weight: 600;
  text-align: center;
}

.modal-catalog-info-price p span {
  padding-left: 8px;
}

.modal-catalog-info-price .btn {
  width: 100%;
}

.modal-catalog-top {
  width: 100%;
}

/* modal catalog free gifts */
.modal-catalog-gifts {
  width: 100%;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid $ui-line-light;
}

.modal-catalog-gifts-info {
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.modal-catalog-gifts-info-indent {
  margin-bottom: 16px;
  margin-top: 12px;

  .delivery-info-text {
    flex-direction: column;
    margin-top: 24px;
    font-size: 10px;
    text-align: center;

    p {
      margin-top: 12px;
    }
  }
}

.modal-catalog-gifts-info h3 {
  margin-bottom: 8px;
}

.modal-catalog-gifts-wrap {
  position: relative;
  width: calc(100% + 16px);
  margin: 0 -8px;
}

.modal-catalog-gifts-info .free-gift-card-info h3 {
  font-size: 12px;
}

.modal-catalog-gifts-info .delivery-info {
  flex-direction: column;
  padding-left: 24px;
  text-align: center;
}

.modal-catalog-gifts-info .delivery-info h3 {
  margin: 14px 0;
  font-size: 12px;
}

.modal-catalog-gifts-info .delivery-info p {
  margin-bottom: 8px;
  font-size: 10px;
}

.desktop-slider {
  display: flex;
}
.mobile-slider {
  display: none;
}

@media all and (max-width: 991px) {
  .modal-catalog-image .modal-catalog-controls {
    opacity: 1;
  }

  .modal-catalog-image .modal-catalog-control {
    left: 0;
    align-items: flex-end;
    width: 100%;
    padding: 0;
    opacity: 1;
  }

  .modal-catalog-control button {
    width: 40px;
    height: 40px;
  }

  .modal-catalog-control button svg {
    width: 16px;
    height: 16px;
  }

  .modal-catalog-info .gift-card.gift-add-card {
    width: 50%!important;
  }

  .modal-catalog-info .modal-catalog-image {
    width: 50%;
  }
  
  .modal-catalog-info .gifts-list .gift-card.gift-add-card:nth-child(2) {
    display: none;
  }

  .modal-catalog-info .gifts-list .gift-card.gift-add-card:nth-child(3) {
    display: none;
  }

  .gifts-item .gifts-list {
    padding-bottom: 40px;
  }

  .gifts-item .gifts-list .gift-list-slide {
    top: auto;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 80px;
    height: 40px;
    opacity: 1;
  }
  
  .gifts-item .gifts-list .gift-list-slide:last-child {
    left: auto;
    right: 0;
  }

  .desktop-slider {
    display: none;
  }
  .mobile-slider {
    display: block;
  }
  
}

@media all and (max-width: 768px) {
  .modal-catalog-slider {
    align-items: center;
  }

  .modal-catalog-image {
    width: 100%;
    min-height: 400px;
    margin-right: 0;
    margin-bottom: 16px;
  }
  .modal-catalog-top {
    flex-direction: column;
  }

  .modal-catalog-gifts {
    flex-direction: column;
  }
  .modal-catalog-gifts-info {
    width: 100%;
  }

  .modal-catalog-gifts-info .delivery-info {
    padding-left: 0;
  }
}

@media all and (max-width: 576px) {
  .modal-catalog-gifts-wrap {
    flex-wrap: wrap;
  }

  .modal-catalog-gifts-wrap .free-gift-card {
    width: 25%;
    padding: 0 4px;

    h3 {
      font-size: 8px;
      line-height: 12px;
    }
  }

  .modal-catalog-slider-zoom {

    img {
      width: calc(100vw - 48px);
      height: auto;
      display: block;
      user-select: none;
    }

  }
}