.bonus-gift-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    height: 50px;
}

.bonus-gift-panel > p {
    
}

.columnPanel {
    display: flex;
    flex-direction: column;
}


@media (max-width: 600px) {
    .bonusField {
        margin: 8px 6px 0 6px;
    }

    .promo-bonus-gift-wrap {
        flex-direction: column;
    }

    .bonus-gift-panel {
        height: auto;
    }
}

@media (min-width: 600px) {
    .bonusField {
        margin: 8px 6px 0 6px;
        width: 25%;
    }
}



.rowBlock {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    flex-wrap:  nowrap;
}

.mr-05 {
    margin-right: 0.5em;
}

.my-6 {
    margin: 6px 0;
}

.promo-bonus-gift-wrap {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.promo-bonus-gift-wrap .bonusField {
    width: 100%;
    max-width: 320px;
    margin-left: 0;
    margin-top: 16px;
}