@import "../../styles/colors.scss";

.catalog-card {
  cursor: pointer;
  flex-direction: column;
  width: 25%;
  margin-bottom: 16px;
  padding: 0 10px;
}

.catalog-card-image {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 20px;
  overflow: hidden;
  background-color: $ui-bg-black;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.catalog-card-image img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  transition: all 1s;
}

.catalog-card.catalog-card-sq {
  position: relative;
  padding-top: 25%;

  .catalog-card-image {
    position: absolute;
    top: 0;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 64px);
    background-color: transparent;
    overflow: hidden;

    .catalog-image-slide.active {
      align-items: center;
      justify-content: center;
    }
  }

  .catalog-card-info h3 {
    font-size: 13px;
  }
}

.catalog-card-slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.catalog-image-slide {
  position: absolute;
  z-index: 0;
  left: 10%;
  flex-direction: column;
  width: 100%;
  opacity: 0;
}

.catalog-image-slide.active {
  position: relative;
  z-index: 1;
  left: 0;
  opacity: 1;
  transition: left 0.65s, opacity 1s;
}

.catalog-slider-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 4px;
  background-color: $ui-bg-slide;
  opacity: 0;
  transition: opacity 1s;
}



.catalog-slider-controls .slide-btn {
  flex: 1;
  height: 4px;
  padding: 0;
  background-color: transparent;
  transition: all 0.3s ease-in-out 0s;
}

.catalog-slider-controls .slide-btn.active {
  background-color: $ui-bg-pink;
  transition: all 0.3s ease-in-out 0s;
}

.catalog-card-control {
  position: absolute;
  top: calc(50% - 78px);
  right: -40px;
  z-index: 4;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 156px;
}

.catalog-card-control button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 6px 0;
  background-color: $ui-bg-white;
  color: $ui-text-gray;
  transition: all 0.3s ease-in-out 0s;
}

.catalog-card .filter-label {
  position: absolute;
  top: 20px;
  right: 16px;
  z-index: 2;
  padding: 8px 12px;
  background-color: $ui-bg-pink;
}

.catalog-card .filter-label span {
  color: $ui-text-white;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  text-transform: uppercase;
}

.catalog-card-info {
  position: relative;
  flex-direction: column;
}

.catalog-card-info-price {
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    margin-bottom: 2px !important;
    margin-right: 16px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 32px;
    margin: 0;
    border-radius: 20px;
    background-color: $ui-bg-cyan;
    color: $ui-text-gray;
    transition: all 0.3s ease-in-out 0s;

    svg {
      fill: $ui-icon-white;
    }
  }
  
  button:hover {
    background-color: $ui-bg-cyan-light;
    color: $ui-text-white;
    transition: all 0.3s ease-in-out 0s;
  }
}

.catalog-card-info h3 {
  height: 32px;
  margin-top: 0;
  color: $ui-text-black;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.catalog-card-info p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  .catalog-card-info-icon {
    position: relative;
    width: 13px;
    height: 13px;
    margin-left: 8px;
    margin-bottom: 2px;
    color: $ui-text-dark;
  }
}

@media all and (max-width: 991px) {
  .catalog-card-image .catalog-card-control {
    right: calc(50% - 93px);
    top: auto;
    bottom: 16px;
    flex-direction: row;
    opacity: 1;
    transition: all 0.5s ease-in-out 0s;
  }

  .catalog-card-control button {
    width: 50px;
    height: 50px;
    margin: 0 6px;
  }

  .catalog-card-control button svg {
    width: 18px;
    height: 18px;
  }
}

@media all and (max-width: 768px) {
  .catalog-card {
    width: 50%;
    padding: 0 4px;
  }

  .catalog-card.catalog-card-sq {
    position: relative;
    padding-top: 50%;
  }

  .catalog-card-image {
    margin-bottom: 0;
  }

  .catalog-card-info h3 {
    height: 30px;
    margin-top: 10px;
    line-height: 22px;
  }

  .catalog-card-info p {
    margin-bottom: 0;
  }

  .catalog-card .filter-label {
    top: auto;
    left: auto;
    right: 8px;
    bottom: 8px;
    padding: 6px 10px;
  }
  
  .catalog-card .filter-label span {
    font-size: 9px;
    line-height: 9px;
  }

}

@media (pointer:fine) {
  .catalog-card-image:hover img {
    transform: scale3d(1.1, 1.1, 1);
    opacity: 1;
    transition: all 1s;
  }

  .catalog-card-image:hover .catalog-slider-controls {
    opacity: 1;
    transition: opacity 1s;
  }

  .catalog-card-control button:hover {
    background-color: $ui-bg-pink;
    color: $ui-text-white;
    transition: all 0.3s ease-in-out 0s;
  }
}