@import "../../styles/colors.scss";

.select {
  position: relative;
  z-index: 0;
  margin-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
}

.select .select-inner {
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  height: 29px;
  min-width: 200px;
  padding: 0px 16px;
  border-radius: 16px;
  background-color: $ui-bg-switch;
  transition: all .25s ease-out 0s;
}

.select .select-inner p {
  font-weight: 500;
}

.select .select-inner img {
  width: 8px;
  height: 8px;
  margin-left: 12px;
}

.select .select-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  flex-direction: column;
  width: 100%;
  max-height: 28px;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
}

.select .select-list.active {
  top: 36px;
  z-index: 3;
  max-height: 92px;
  opacity: 1;
  background-color: $ui-bg-white;
}

.select .select-list .select-option {
  flex-shrink: 0;
  width: 100%;
  min-height: 28px;
  padding: 8px 16px;
  border-bottom: 1px solid $ui-line-light;
  background-color: $ui-bg-white;
  color: $ui-text-gray;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
}