@import "../../styles/colors.scss";

/* HOME HEADER SECTION */

.gifts-header {
  flex-direction: column;
  width: 100%;
}

/* HOME CATALOG SECTION */

.gifts-catalog-categories {
  flex-wrap: wrap;
  position: relative;
  width: calc(100% + 20px);
  margin: 0 -10px;
}

.gifts-catalog-container {
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 0 -10px;

  & > h3 {
    width: 100%;
    margin-bottom: 12px;
    padding: 0 10px;
  }
}

.gifts-catalog {
  width: 100%;

  .wrapper {
    flex-direction: column;
    padding-top: 30px;
  }
}

.gifts-catalog-wrap {
  flex-direction: column;
  width: 100%;
  padding: 0 0 40px 0;
}

.gifts-title {
  width: 100%;
}

.gift-cards {
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 0 -10px;
}

  .resized.gift-card {
    margin-bottom: 8px;
  }

 .resized {
  width: auto!important;
  padding: 0!important;
  


  .gift-card-image {
    display: none;
  }

  .gift-card-info {
    padding: 4px 12px;
    margin-right: 8px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
  }

}

 .red .gift-card-info {
  border-color: #db3762;
  color:  #db3762;
}

/* HOME FOOTER SECTION */

.gifts-footer {
  width: 100%;
}