@import "../../styles/colors.scss";

/* HOME HEADER SECTION */

.home-header {
  flex-direction: column;
  width: 100%;
}

.home-info-slider {
  width: 100%;
}

/* HOME CATALOG SECTION */

.home-catalog {
  z-index: 2;
  width: 100%;
}

/* HOME GIFTS SECTION */

.home-gifts {
  z-index: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.home-gifts h2 {
  margin: 24px 0;
  font-size: 32px;
  line-height: 38px;
}

@media all and (max-width: 991px) {
  .home-gifts h2 {
    padding: 0 16px;
  }
}

.home-gifts .gifts-wrap {
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin: 0 -15px;
  padding: 0 0 70px 0;
}

.home-catalog {
  .wrapper {
    padding-right: 0;
    padding-left: 0;

    .catalog-slider-title {
      padding: 20px 30px;
    }

    .catalog-slider {
      .catalog-slider-scroll {
        padding: 0 20px;
      }
    }

    .catalog {
      margin-top: 32px;
      padding: 0 20px;
    }
  }
}

/* HOME FOOTER SECTION */

.home-footer {
  width: 100%;
}