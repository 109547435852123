@import "../../styles/colors.scss";

/* BASKET HEADER SECTION */

.basket-header {
  flex-direction: column;
  width: 100%;
}

/* BASKET CONTENT SECTION */

.basket-content {
  width: 100%;
  padding: 20px 0;

  .basket-content-inner {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .order-info {
    flex-direction: column;
    padding: 24px 0 10px 0;
  }

  .order-info p {
    font-weight: 700;
  }

  .basket-add-more {
    flex-direction: column;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid $ui-line-light;
  }

  .basket-add-more h2 {
    font-size: 24px;
    line-height: 36px;
  }

  .basket-add-more .add-more {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
    padding-bottom: 24px;
  }

  .basket-add-more .add-more .gift-card {
    margin-bottom: 0;
  }

  .basket-add-more .add-more > p {
    margin-bottom: 8px;
    padding-right: 50px;
    color: $ui-text-gray;
    font-weight: 500;
  }

  .basket-wrap {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .basket-order-info {
    flex-direction: column;
    width: 60%;
    padding-bottom: 16px;
    padding-right: 40px;
  }

  .basket-order-info h2 {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 36px;
  }

  .basket-order-info h2 button {
    padding: 2px 0 0 12px;
    font-size: 20px;
  }

  .basket-order-info h4 {
    margin-bottom: 4px;
  }

  .basket-order-info p {
    margin-bottom: 16px;
  }

  .basket-order {
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid $ui-line-light;
  }

  .basket-order > h3 {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 30px;
  }

  .basket-order p {
    margin-bottom: 8px;
    font-weight: 600;
  }

  .basket-order > p {
    margin-bottom: 0;
    color: $ui-text-gray;
    font-size: 11px;
    font-weight: 500;
  }

  .basket-empty {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
  .basket-empty img {
    width: 64px;
    height: 64px;
    margin-bottom: 8px;
  }
  .basket-empty h3 {
    margin-bottom: 4px;
    text-align: center;
  }
  .basket-empty p {
    text-align: center;
  }

  .basket-order-promo {
    flex-direction: column;
    width: 40%;
    margin-bottom: 0;
    padding: 32px 0 0 0;
    padding-left: 40px;
  }

  .basket-order-promo h3 {
    margin-bottom: 8px;
  }

  .basket-free-btn p {
    color: $ui-text-gray;
    font-weight: 500;
  }
}

/* BASKET FOOTER SECTION */

.basket-footer {
  width: 100%;
}

.top-btn {
  position: fixed;
  top: 100%;
  right: 16px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border: 1px solid $ui-line-pink;
  background-color: $ui-bg-pink;
  opacity: 0;
  transition: all 0.3s ease-out 0s;
}

.top-btn.show {
  display: flex;
  top: calc(100% - 60px);
  opacity: 1;
  transition: all 0.3s ease-out 0s;
}

.top-btn:hover {
  background-color: $ui-bg-white;
  transition: all 0.3s ease-out 0s;
}

.top-btn .top-btn-icon {
  width: 18px;
  height: 18px;
  color: $ui-text-white;
  transition: all 0.3s ease-out 0s;
}

.top-btn:hover .top-btn-icon {
  color: $ui-text-pink;
  transition: all 0.3s ease-out 0s;
}

.mobile-basket {
  display: none;
}

.mobile-basket-info {
  display: none;
}

@media all and (max-width: 1060px) {
  .basket-content {
    .basket-order-info {
      padding-right: 8px;
    }
    .basket-order-promo {
      padding-left: 8px;
    }
  }
}

@media all and (max-width: 991px) {
  .basket-wrap {
    width: 100%;
  }
  .basket-content {
    .basket-order-info {
      width: 100%;
      padding-right: 0;
    }

    .basket-order-promo {
      width: 100%;
      padding-left: 0;
    }
  }

  .basket-add-more {
    padding-right: 0;
    border-width: 0;
  }

  .mobile-basket-wrap {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    align-items: center;
    justify-content: flex-start;
    max-width: calc(100% - 40px);
  }

  .mobile-basket {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: $ui-bg-pink;
    color: $ui-text-white;
  }

  .mobile-basket svg {
    width: 18px;
    height: 18px;
  }

  .mobile-basket p {
    margin-left: 6px;
    font-size: 20px;
    font-weight: 600;
  }

  .mobile-basket-wrap .mobile-basket-info {
    display: flex;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .mobile-basket-wrap .mobile-basket-info.active {
    width: auto;
    height: auto;
  }

  .mobile-basket-wrap .mobile-basket-info p {
    padding: 16px;
    border-radius: 8px;
    background-color: $ui-text-black;
    color: $ui-text-white;
    opacity: 0;
    transition: all 0.35s ease-out 0s;
  }

  .mobile-basket-wrap .mobile-basket-info.active p {
    opacity: 1;
    transition: all 0.35s ease-out 0s;
  }
}
