@import "../../styles/colors.scss";

.page-title-bg {
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -1px;
  background-position: 50% 50%;
  background-size: cover;
}

.title-shadow {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.page-title-wrap {
  flex-direction: column;
  width: 100%;
  padding-top: 35px;
}

.page-title-wrap .breadcrumbs {
  align-items: center;
  margin-bottom: 8px;

  & > div {
    align-items: center;
  }
}

.page-title-wrap .breadcrumbs a, 
.page-title-wrap .breadcrumbs p {
  font-size: 14px;
  cursor: pointer;
}

.page-title-wrap .breadcrumbs a:hover {
  color: $ui-text-pink;
}

.page-title-wrap .title-icon {
  margin: 0 8px 0 10px;
  font-size: 10px;
}

.page-title-wrap .page-title {
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 600;
}