@import "../../styles/colors.scss";

.gift-card.gift-add-card {
  cursor: pointer;
  flex-direction: column;
  width: 25%;
  margin-bottom: 16px;
  padding: 0 10px;
}

.gift-add-card .gift-card-image {
  position: relative;
  width: 100%;
  margin-bottom: 4px;
  overflow: hidden;
}

.gift-add-card .gift-card-image {
  border: 2px solid $ui-line-white;
}

.gift-card-image img {
  position: relative;
  z-index: 1;
  width: 100%;
  transition: transform 1s;
}

.gift-card-image:hover img {
  transform: scale3d(1.1, 1.1, 1);
  transition: transform 1s;
}

.gift-card-image:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: calc(100% - 4px);
  background-color: $ui-bg-hover;
  opacity: 0;
  transition: opacity 1s;
}

.gift-card-image:hover:before {
  opacity: 1;
  transition: opacity 1s;
}

.gift-card-slider {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.gift-image-slide {
  position: absolute;
  z-index: 0;
  left: 10%;
  flex-direction: column;
  opacity: 0;
}

.gift-image-slide.active {
  position: relative;
  z-index: 1;
  left: 0;
  opacity: 1;
  transition: left 0.65s, opacity 1s;
}

.gift-card-info {
  flex-direction: column;
  margin-bottom: 4px;
  overflow: hidden;
}

.gift-add-card .gift-card-info h3 {
  max-height: 32px;
  margin-top: 6px;
  color: $ui-text-black;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
}

.gift-add-card .gift-card-info p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.gift-add-card .add-gift-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.15s, opacity 1s;
}

.gift-card:hover .add-gift-btn {
  opacity: 1;
  transition: all 0.15s, opacity 1s;
}

.gift-add-card.active .add-gift-btn {
  opacity: 1;
  transition: all 0.15s, opacity 1s;
}

.gift-add-card .add-gift-btn p {
  margin-bottom: 0;
  padding: 2px 4px;
  background-color: $ui-bg-gray;
  color: $ui-text-white;
  font-weight: 600;
}

.gift-add-card.active .gift-card-image {
  border: 2px solid $ui-line-pink;
}

.gift-add-card.active .gift-card-info h3 {
  color: $ui-text-pink;
  font-weight: 600;
}

/*
@media all and (max-width: 768px) {
  .gift-card {
    padding: 0 4px;
  }

  .gift-card-image {
    margin-bottom: 0;
  }

  .gift-card-info h3 {
    max-height: 36px;
    font-size: 12px;
    line-height: 18px;
  }

  .gift-card-info p {
    margin-bottom: 0;
  }
}

@media all and (max-width: 640px) {
  .gift-card {
    width: 50%;
  }
}
*/