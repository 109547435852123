@import "../../styles/colors.scss";

/* PROFILE HEADER SECTION */

.profile-header {
  flex-direction: column;
  width: 100%;
}

/* PROFILE CONTENT SECTION */

.profile-content {
  width: 100%;
  padding: 20px 0;
}

.profile-content-inner {
  flex-direction: row;
  width: 100%;
}

.profile-content-inner h2 {
  font-size: 24px;
  line-height: 36px;
}

.profile-orders {
  flex-direction: column;
  width: 66.66666%;
  padding: 16px 16px 16px 0;
  border-right: 1px solid $ui-line-light;
}

.profile-orders .profile-orders-list {
  position: relative;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin: 0 -8px;
  padding: 16px 0;
}

.profile-user {
  flex-direction: column;
  width: 33.33334%;
  padding: 16px 0 16px 16px;
}

.profile-user .profile-bonuses {
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid $ui-line-light;
}

.profile-user .profile-bonuses .profile-bonuses-count {
  width: 100%;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
}

.profile-user .profile-bonuses .profile-bonuses-count p {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.profile-user .profile-bonuses .profile-bonuses-count p .bonus-icon {
  margin-bottom: 2px;
  margin-left: 8px;
}

.profile-user .profile-bonuses > p {
  margin-bottom: 8px;
  font-size: 12px;
}

.profile-user .profile-form {
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $ui-line-light;
}

.profile-user .profile-form p {
  margin-bottom: 12px;
}

.profile-user .profile-form .btn-pink-wrap {
  margin-bottom: 8px;
}

.profile-user .profile-app {
  flex-direction: column;
  padding: 16px 0;
}

.profile-user .profile-app .profile-app-links {
  margin-top: 12px;
}

.profile-user .profile-app .profile-app-links img {
  width: 140px;
  margin-right: 8px;
}

.profile-mobile-tabs {
  display: none;
}

/* PROFILE FOOTER SECTION */

.basket-footer {
  width: 100%;
}

p > a {
  color: $ui-line-pink;
  text-decoration: underline;
}

@media all and (max-width: 991px) {
  .profile-content {
    padding: 0;
  }

  .profile-content-inner {
    flex-direction: column;
  }

  .profile-orders {
    display: none;
    width: 100%;
    padding: 16px 0;
    border-right-width: 0;
  }

  .profile-orders.active {
    display: flex;
  }

  .profile-user {
    display: none;
    width: 100%;
    padding: 16px 0;
  }

  .profile-user.active {
    display: flex;
  }

  .profile-mobile-tabs {
    display: flex;
  }

  .profile-mobile-tabs button {
    margin-right: 8px;
    padding: 6px 16px;
    border: 1px solid $ui-line-light;
  }

  .profile-mobile-tabs button.active {
    border-color: $ui-line-pink;
    background-color: $ui-bg-pink;
    color: $ui-text-white;
    font-weight: 600;
  }
}