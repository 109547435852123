@import "../../styles/colors.scss";

.cookie-notice {
    box-sizing: border-box;
    position: fixed;
    bottom: 20px;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    padding: 12px;
    background: white;
    box-shadow: 0 2px 4px rgba(20, 40, 80, 0.2);
    border-radius: 3px;
    font-size: 0.86em;
    line-height: 1.5;
    color: #456;
    transform: translate3d(0, 0, 0);
    animation: cookie-notice-fadein 500ms ease;
    z-index: 20;
  }
  
  @keyframes cookie-notice-fadein {
    0% { transform: translate3d(0, 128px, 0); opacity: 0; }
    100% { transform: translate3d(0, 0, 0); opacity: 100; }
  }
  
  .cookie-notice-message > p {
    margin: 0;
    padding: 0;
    width: 80%;
  }
  
  .cookie-notice > button {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    width: 20%;
    color: dodgerblue;
    position: absolute;
    top: 8px;
    right: 0;
    width: 18%;
    bottom: 8px;
    cursor: pointer;
    font-weight: bold;
    border-left: solid 1px rgba(0, 40, 80, 0.1);
  }
  
  .cookie-notice > button:hover {
    color: #4ba6ff;
  }
  
  .cookie-notice > button:active {
    color: #1873cc;
  }

  @media all and (max-width: 768px) {
    .cookie-notice {
      bottom: 90px;
      width: calc(100% - 24px);
      margin-left: calc(12px - 50%);
    }
  }